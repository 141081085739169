<script>
export default {

  // 格式化货币的格式
  formatPrice(value, size) {

    if (parseFloat(value) === 0) {
      return parseFloat("0").toFixed(size);
    } else {
      return parseFloat(value).toFixed(size);
    }
  },

  // 设置 0 价格的显示灰色
  priceColor(value) {

    if (parseFloat(value) === 0) {
      return "color: #cccccc; text-align: right;"
    } else {
      return "color: #225DDC; text-align: right;"
    }
  },

  // 设置 0 价格的显示灰色
  priceColor2(value) {

    if (parseFloat(value) === 0) {
      return "color: #cccccc;"
    } else {
      return "color: #225DDC;"
    }
  }

};
</script>
