<template>
  <a-spin class="loadingClass" v-if="dataValue.loading === true" tip="正在加载数据 ..."></a-spin>
  <div v-if="dataValue.loading === false">
    <div class="divSearch">
      <a-input class="company" :placeholder="dataValue.crmTypeName+'公司名'"
               v-model:value="dataValue.inquiryList.input.companyName"
               @pressEnter="allHttp.inquiryList()"/>
      <a-input class="input" placeholder="型号名称" @pressEnter="allHttp.inquiryList()"
               v-model:value="dataValue.inquiryList.input.modelName"></a-input>
      <a-input class="input" placeholder="品牌名称" @pressEnter="allHttp.inquiryList()"
               v-model:value="dataValue.inquiryList.input.manufacturerName"></a-input>
      <a-input class="number" v-inputNumber @pressEnter="allHttp.inquiryList()" placeholder="批次"
               v-model:value="dataValue.inquiryList.input.userBatchValue"></a-input>
      <a-button type="primary" @click="allHttp.inquiryList()">查询</a-button>
      <div class="allCount">共有记录: {{ dataValue.inquiryList.output.allCount }} 条</div>
    </div>



    <div style="padding:15px;margin-bottom: 20px;">
      <table class="ahuiTable">
        <tr>
          <th>编号</th>
          <th>批次</th>
          <th>{{ dataValue.crmTypeName }}公司名称</th>
          <th>姓名</th>
          <th>型号</th>
          <th>批次</th>
          <th>数量</th>
          <th>价格</th>
          <th>创建时间</th>
          <th>回复价格</th>
          <th>回复货币</th>
          <th>回复时间</th>
          <th>信息</th>
          <th>回复</th>
          <th>分析</th>
        </tr>
        <tr v-for="aRow in dataValue.inquiryList.output.list"
            :style="allEvent.selectRow.selectRowColor(aRow['userNodeId'])"
            @click="allEvent.selectRow.selectRowEvent(aRow['userNodeId'])">
          <td class="min">{{ aRow['rowNumber'] }}</td>
          <td class="min">{{ aRow['userBatchId'] }}</td>
          <td>{{ aRow['crmCompanyName'] }}</td>
          <td class="min">{{ aRow['crmUserName'] }}</td>
          <td class="min">{{ aRow['modelName'] }}</td>
          <td class="min">{{ aRow['batchName'] }}</td>
          <td class="min number">{{ aRow['number'] }}</td>
          <td class="price" style="width: 120px;">{{ aRow['price'] }}</td>
          <td class="min">{{ aRow['createTime'] }}</td>
          <td class="price" style="width: 120px;">{{ aRow['replyPrice'] }}</td>
          <td class="min">{{ aRow['replyMoneyTypeName'] }}</td>
          <td class="min">{{ aRow['replyTime'] }}</td>
          <td class="min"><a @click="allEvent.companyInfo.userInfoOpen(aRow)">信息</a></td>
          <td class="min"><a @click="allEvent.reply.goOpen(aRow)">回复</a></td>
          <td class="min"><a class="aBQSet" @click="allEvent.bi.open(aRow)">分析</a></td>
        </tr>
        <tr style="background-color: #ffffff;">
          <td colspan="20" class="pages">
            <APages :number_of_page="dataValue.inquiryList.input.numberOfPage"
                    :all_count="dataValue.inquiryList.output.allCount"
                    :this_page="dataValue.inquiryList.input.page"
                    :all_page="dataValue.inquiryList.output.allPage"
                    @toPage="allEvent.inquiryList.toPage"
            />
          </td>
        </tr>
      </table>

    </div>
  </div>

  <a-drawer
      class="aDrawer"
      :title="'查看'+ dataValue.crmTypeName +'公司信息'"
      width="300"
      :visible="dataValue.companyInfo.visible"

      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.companyInfo.userInfoClose"
  >
    <userInfo :company_id="dataValue.companyInfo.crmCompanyId"/>
  </a-drawer>


  <a-drawer
      :title="dataValue.reply.title"
      width="1400"
      :visible="dataValue.reply.visible"
      :maskClosable="false"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.reply.goClose"
  >
    <reply :row_obj="dataValue.reply.rowObj" @replyGoClose="allEvent.reply.goClose"
           :crm_type_id="dataValue.crmTypeId" :crm_type_name="dataValue.crmTypeName"/>
  </a-drawer>

  <a-drawer
      :title="dataValue.bi.modelName +'的分析信息'"
      width="1150"
      :visible="dataValue.bi.show"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.bi.close"
  >
    <biModel :model_name="dataValue.bi.modelName"  />
  </a-drawer>

</template>

<script>


import {reactive} from "vue";
import {useRoute} from 'vue-router';
import httpRequest from "@/utils/axios";
import {message} from "ant-design-vue";
import APages from "@/tools/pages"
import userInfo from "@/view/company/user_info"
import reply from "@/view/order/user.need.reply"
import biModel from "@/view/bi/bi_model"

export default {
  name : "factory.sport.detail",
  emits : [ 'setMenuKey' ],
  components : { APages, userInfo, reply, biModel },
  setup(props, { emit }) {

    let dataValue = reactive({
      bi:{
        show: false,
        modelName : ""
      },
      crmTypeId : 0,
      crmTypeName : "",
      selectRow : 0,
      loading : false,
      inquiryList : {
        input : {
          crmTypeId : 0,
          page : 1,
          numberOfPage : 50,
          companyName : "",
          userBatchValue : "",
          userBatchId : 0,
          userNodeId : 0,
          manufacturerName : "",
          modelName : "",
          isDownload : 0
        },
        output : {
          allCount : 0,
          allPage : 1,
          list : []
        }
      },
      companyInfo : {
        crmCompanyId : 0,
        visible : false,
      },
      reply : {
        title : "",
        rowObj : null,
        visible : false,
      }
    })

    // 区分是工厂销售还是贸易商
    switch (useRoute().params.type.toLowerCase()) {
      case "factory.spot":
        emit('setMenuKey', 'dj.gcxhmx');
        dataValue.crmTypeId = 3;
        dataValue.crmTypeName = "工厂销售";
        break;
      case "merchant": // 贸易商
        emit('setMenuKey', 'dj.mysbjmx');
        dataValue.crmTypeId = 2;
        dataValue.crmTypeName = "贸易商";
        break;
      default:
        return;
    }


    let allHttp = {

      inquiryList : () => {

        dataValue.inquiryList.input.userBatchId = parseInt(dataValue.inquiryList.input.userBatchValue);
        dataValue.inquiryList.input.crmTypeId = dataValue.crmTypeId;
        dataValue.loading = true;

        httpRequest.post('/admin/user.need/inquiry.list', dataValue.inquiryList.input)
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                dataValue.inquiryList.output.allPage = res.data.allPage;
                dataValue.inquiryList.output.allCount = res.data.allCount;
                dataValue.inquiryList.output.list = res.data.list;
                dataValue.loading = false;
              }
            })
      },

      init : () => {
        allHttp.inquiryList();
      }

    }
    allHttp.init();


    let allEvent = {

      bi : {

        open : (row)=>{
          // console.log(row['modelName'])
          dataValue.bi.modelName = row['modelName'];
          dataValue.bi.show = true;
        },

        close: ()=>{
          dataValue.bi.show = false;
        }

      },

      inquiryList : {

        toPage : (pageNumber) => {
          dataValue.inquiryList.input.page = pageNumber;
          allHttp.inquiryList();
        }

      },

      selectRow : { // 选择其中一行
        selectRowEvent : (rowId) => {
          dataValue.selectRow = rowId;
        },
        selectRowColor : (rowId) => {
          return dataValue.selectRow === rowId ? "background-color:#d8ffd8;" : "";
        }
      },

      companyInfo : {

        userInfoOpen : (row) => {
          dataValue.companyInfo.crmCompanyId = row['crmCompanyId'];
          dataValue.companyInfo.visible = true;
        },
        userInfoClose : () => {
          dataValue.companyInfo.visible = false;
        }

      },

      reply : {

        goOpen : (row) => {
          dataValue.reply.rowObj = row;
          dataValue.reply.visible = true;
          dataValue.reply.title = "回复来自: " + row['crmCompanyName'] + " 的BOM信息"
        },

        goClose : (rowObj) => {
          dataValue.reply.visible = false;
        }

      }

    }


    return {
      dataValue,
      allHttp,
      allEvent
    }
  }
}
</script>

<style scoped>
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
.divSearch { padding: 15px 15px 0 15px; }
.divSearch * { margin-right: 20px; }
.divSearch .company { width: 350px; }
.divSearch .input { width: 170px; }
.divSearch .number { width: 100px; text-align: center; }
.allCount { float: right; line-height: 36px; }
</style>